
import { useState } from "react";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import AppRouter from "./routes/AppRouter";



function App() {
  
  const [isNavbarOpen, setIsNavbarOpen] = useState(false)
  return (
    <div className={`h-screen w-screen overflow-auto ${isNavbarOpen?"overflow-hidden":"overflow-auto"} `}>
      
      <Navbar data={{isNavbarOpen, setIsNavbarOpen}} />

      <AppRouter />
      {/* banner 5 */}
      <Footer />
    </div>
  );
}

export default App;
