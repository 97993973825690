import React from 'react'

function Button({ label,type,onClickFunc }) {
    return (
        <>
            <button onClick={()=>{
                console.log("fdsaf");
                if (onClickFunc) {
                    
                    onClickFunc()
                }
            }} className={`lg:px-6 lg:py-2 py-1 px-3 text-white font-semibold  rounded-md text-base lg:text-lg w-fit ${type=="green"?"bg-[#01C47E]":"bg-[#0097CE]"}`}>
                {label}
            </button>
        </>
    )
}

export default Button