import React from 'react'
import { banner8Data } from '../asset/staticData'
import Card5 from '../components/Card5'
import Button from '../components/Button'
import { useNavigate } from 'react-router-dom';

function Sarvice() {
    const navigate = useNavigate();
    return (
        <>
            <div className="banner6  bannerPadding-px bannerPadding-py py  ">
                <h1 className='text-white font-semibold text-3xl md:text-4xl xl:text-5xl xl:mb-6 md:mb-4 mb-3  w-full xl:w-2/3 3xl:w-1/2 relative z-20 text-center md:text-left'>
                    Financial Solutions
                    <br />
                    Tailored for Your Success
                </h1>
                <p className='text-base text-center md:text-left text-white relative z-20  w-full xl:w-2/3 3xl:w-1/2'>
                    Welcome to our comprehensive suite of financial solutions designed to meet the unique needs of your business. At Genesis Equity Group , we believe in providing swift, flexible, and hassle-free funding options to drive your business forward.
                </p>
            </div>
            {/* banner7 */}
            <div className="banner7 bannerPadding-px   w-full  relative z-10 ">
                <div className='px-6 py-8 md:gap-20 gap-16 flex flex-col xl:gap-24 md:px-10 md:py-14  bg-white'>

                    {banner8Data?.map((item, i) => {
                        return <Card5 data={{ ...item, i }} key={i} />
                    })}
                </div>
            </div>
            <div className='banner8 bannerPadding-px  bannerPadding-py  w-full  '>
                <div className='w-full xl:w-1/2 md:w-2/3 flex flex-col gap-4 xl:gap-6 '>
                    <h3 className='text-white text-center md:text-left xl:text-5xl md:text-4xl text-3xl font-bold'>
                        Take the Next Step Toward Financial Success
                    </h3>
                    <p className='text-base text-center md:text-left text-white'>
                        Ready to elevate your business to new heights? Get in touch with our team today to unlock the funding your business deserves.
                    </p>
                    <div className='flex justify-center md:justify-start'>

                        <Button label={"Get Funded Now"} onClickFunc={() => navigate("/contact")}></Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Sarvice