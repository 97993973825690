import React from 'react'

function Card2({ data }) {
    let { label, para } = data;
    return (
        <div className=' rounded-md border border-[#0097CE] border-opacity-95 relative overflow-hidden w-full p-6 flex flex-col justify-center'>
            <div className='absolute right-0 top-0 h-20 w-20'>

            <span className='h-28 w-28 rounded-full bg-[#00C380] bg-opacity-40 absolute p2'></span>
            <span className='h-28 w-28 rounded-full bg-[#0097CE] bg-opacity-40 absolute p1'></span>
            </div>

            <h5 className='font-semibold mb-2 text-xl'>{label}</h5>
            <p className='text-base '>
                {para}
            </p>

        </div>
    )
}

export default Card2