import React from 'react'

function Card4({ data }) {
    let { label, icon, para } = data ? data : {}
    return (
        <div className='w-full gap-3 md:gap-4 xl:gap6 flex items-center md:items-center flex-col text-white h-fit'>
            <div className="logo h-16 w-16 md:h-20  md:w-20 rounded-md bg-white bg-opacity-25 flex items-center justify-center">
                {icon}
            </div>
            <h5 className='text-white text-xl font-semibold text-center md:text-left'>{label}</h5>
            <p className='text-white text-lg text-center md:text-left'>{para}</p>
        </div>
    )
}

export default Card4