import React from 'react'
import { Link } from 'react-router-dom';

function Card1({ data }) {
    let { img, label, para } = data;
    return (
        <>
            <div className='w-[15rem] md:w-[20rem]'>
                <img src={img} alt="" className='mb-4 xl:mb-6 rounded-md' />
                <h5 className='font-bold text-2xl mb-4 xl:mb-6'>{label}</h5>
                <p className='mb-4 xl:mb-6 text-wrap '>{para}</p>
                <Link to={"/services"} className="flex items-center font-semibold hover:navHover">
                    Learn more <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 ml-2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                    </svg>

                </Link>
            </div>
        </>
    )
}

export default Card1