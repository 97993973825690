import React, { useEffect, useRef, useState } from 'react'
import { banner8Data, banner9Data } from '../asset/staticData'
import Card5 from '../components/Card5'
import Button from '../components/Button'
import emailjs from '@emailjs/browser';
import PopUP from '../components/PopUP';
import Loader from '../components/Loader';

function Contact() {
    const form = useRef();
    const [isPopUpOpen, setIsPopUpOpen] = useState(false)
    const [isLoaderOn, setIsLoaderOn] = useState(false)
    const [isPopUpOpenText, setIsPopUpOpenText] = useState("Email Sent Successfully!!")
   

    const sendEmail = (e) => {
        e.preventDefault();
        console.log();
        const usPhoneReg = /^\+?1?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
        // const usPhoneReg=/^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
        if (!usPhoneReg?.test(e.target.elements.phone.value)) {
            // if (`${e.target.elements.phone.value}`.length != 10) {
            setIsPopUpOpen(true)
            setIsPopUpOpenText("Please Enter Correct Phone Number!!")
            return;
        }
        const emailReg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!emailReg?.test(e.target.elements.email.value)) {
            setIsPopUpOpen(true)
            setIsPopUpOpenText("Please Enter Correct Email!!")
            return;
        }

       
        setIsLoaderOn(true)
        emailjs
            .sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, {
                publicKey: process.env.REACT_APP_PUBLIC_KEY,
            })
            // .sendForm('service_yqxv2f8', 'template_vf0cdmn', form.current, {
            //     publicKey: 'tJm2YCnRH4F67MJpe',
            // })
            .then(
                () => {
                    console.log('SUCCESS!');
                    setIsPopUpOpen(true)
                    setIsPopUpOpenText("Email Sent Successfully!!")
                    // alert("Email Sent Successfully!!")
                    e.target.reset();
                    setIsLoaderOn(false)
                },
                (error) => {
                    console.log('FAILED...', error.text);
                    setIsPopUpOpen(true)
                    setIsPopUpOpenText("Something Went Wrong!!")
                    e.target.reset()
                    setIsLoaderOn(false)
                },
            );
    };






    return (
        <>
            {isLoaderOn && <Loader />}

            {isPopUpOpen && <PopUP data={{
                setIsPopUpOpen,
                isPopUpOpenText
            }} />}

            <div className="banner9  bannerPadding-px bannerPadding-py py  ">
                <h1 className='text-white font-semibold text-3xl md:text-4xl xl:text-5xl xl:mb-6 md:mb-4 mb-3  w-full xl:w-2/3 3xl:w-1/2 relative z-20 text-center md:text-left'>
                    Get in Touch with us
                </h1>
                <p className='text-base text-white relative z-20  w-full xl:w-2/3 3xl:w-1/2 text-center md:text-left'>
                    Get a quote in minutes and funded in the same day
                </p>
            </div>
            {/* banner7 */}
            <div className="banner7 bannerPadding-px   w-full  relative z-10 ">
                <div className='px-4 py-6 md:gap-20 gap-16 flex flex-col lg:flex-row xl:gap-24 md:px-10 md:py-14  bg-white'>
                    <div className="left w-full lg:w-1/2">

                        {/* item */}
                        {banner9Data?.map(({ svg, label, para }, i) => {
                            return <div className="item w-full flex  mb-10 md:mb-14" key={i}>
                                <div className="smallLogo h-10 w-10 flex flex-shrink-0 items-center justify-center rounded-md">
                                    {svg}
                                </div>
                                <div className='ml-6 w- '>
                                    <p className='text-xl font-semibold'>
                                        {label}
                                    </p>
                                    <p className='text-base w-40 sm:w-full overflow-hidden break-words pr-2 text-ellipsis'>
                                        {para}
                                    </p>
                                </div>
                            </div>
                        })}


                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1512.2988123466318!2d-74.00858550757552!3d40.704858671558966!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25a161aaa68c5%3A0x74dd64c98661252f!2s99%20Wall%20St%20%232497%2C%20New%20York%2C%20NY%2010005%2C%20USA!5e0!3m2!1sen!2sin!4v1707211403029!5m2!1sen!2sin" className='h-[15rem] md:h-[25rem] w-full max-w-[30rem] rounded-md' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                    <div className="right  w-full lg:w-1/2 ">
                        <form ref={form} onSubmit={sendEmail}>


                            <div>
                                <label htmlFor="" className='text-2xl font-medium '>
                                    Name
                                </label>
                                <input required type="text" name='from_name' placeholder='Enter Your Name' className='text-base text-[#37393F] text-opacity-45  my-5 border-gray-200 rounded-lg w-full border px-5 py-4' />
                            </div>
                            <div>
                                <label htmlFor="" className='text-2xl font-medium '>
                                    Phone
                                </label>
                                <input required type="text" name='phone' placeholder='Ex. (123) 123-1234' className='text-base text-[#37393F] text-opacity-45  my-5 border-gray-200 rounded-lg w-full border px-5 py-4' />
                            </div>
                            <div>
                                <label htmlFor="" type="email" className='text-2xl font-medium '>
                                    Email
                                </label>
                                <input required type="text" name='email'
                                    placeholder='Enter Your Email' className='text-base text-[#37393F] text-opacity-45  my-5 border-gray-200 rounded-lg w-full border px-5 py-4' />
                            </div>
                            <div>
                                <label htmlFor="" className='text-2xl font-medium '>
                                    Service Interested in
                                </label>
                                <input required type="text" name='service' placeholder='Ex.MCA' className='text-base text-[#37393F] text-opacity-45  my-5 border-gray-200 rounded-lg w-full border px-5 py-4' />
                            </div>
                            <div>
                                <label htmlFor="" className='text-2xl font-medium '>
                                    Messege
                                </label>
                                <textarea required type="text" name='message' placeholder='Type your queries here...' className='text-base text-[#37393F] text-opacity-45 h-60 my-5 border-gray-200 rounded-lg w-full border px-5 py-4' />
                            </div>
                            <Button label={"Send Messege"}></Button>
                        </form>
                    </div>


                </div>
            </div>

        </>
    )
}

export default Contact