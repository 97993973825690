import React from 'react'
import { Link } from 'react-router-dom';
import logo from "../images/logo.png"
import Button from './Button';

function Footer() {
    return (
        <div className='bannerPadding-px mt-12 md:mt-26 xl:mt-20'>
            <div className="logo flex justify-center md:justify-start">
                <Link to={"/"}>

                    <img src={logo} alt="" srcset="" className='h-12  w-28 md:h-14 md:w-32 lg:h-[4.375rem] lg:w-[9.875rem]' />
                </Link>
            </div>
            <div className='flex flex-col xl:flex-row justify-between xl:items-center pb-3'>
                <div className="grid grid-cols-2 grid-rows-2 left md:flex gap-2 md:gap-3 xl:gap-7 w-full justify-between md:justify-start">
                    <Link to={"/"} className='font-medium text-sm md:text-lg  hover:navHover text-center md:text-left' >
                        Home
                    </Link>
                    <Link to={"/services"} className='font-medium  text-sm md:text-lg hover:navHover text-center md:text-left' >
                        Services
                    </Link>
                    <Link to={"/contact"} className='col-span-2 font-medium  text-sm md:text-lg hover:navHover text-center md:text-left' >
                        Contact Us
                    </Link>
                    {/* <Link to={"/"} className='font-medium  text-sm md:text-lg hover:navHover text-center sm:text-left' >
                        Customer Care
                    </Link> */}
                </div>

                {/* <div className="right w-full mt-4 xl:mt-0  xl:w-fit">


                    <p className='text-base mb-3 text-center md:text-left'>
                        Get the freshest news from us
                    </p>
                    <div className='flex md:flex-row flex-col items-center justify-between gap-2'>
                        <input type="text" placeholder='Your Email Address...' className='border rounded-md p-3 w-64 text-xs ' />
                        <Button label={"Subscribe"} />

                    </div>
                </div> */}
            </div>
            <hr />
            <div className='flex items-center justify-center flex-col lg:flex-row py-4'>

                {/* <Link to={"https://jumpstairs.com/"} className='text-base text-center pb-4 lg:pb-0 text-[#92989F]'>Designed and Developed by jumpstairs </Link> */}


                {/* <p className='text-base text-center  text-[#92989F] mx-4 hidden lg:block'> | | </p> */}
                <p className='text-base text-center  text-[#92989F]'>Copyright © Genesis Equity Group 2024. All right reserved</p>
            </div>

        </div>
    )
}

export default Footer