import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import logo from "../images/logo.png"

function Navbar({ data }) {
    const { isNavbarOpen, setIsNavbarOpen } = data;
    const location = useLocation();
    useEffect(() => {
        setIsNavbarOpen(false)
    }, [location])
    // console.log(location);
    return (
        <>
            {isNavbarOpen && <div className=' lg:hidden absolute h-screen w-screen left-0  z-40 bg-white items-center gap-6 py-10 flex flex-col  navlinks top-[100px]'>
                <div className='flex flex-col justify-between items-center gap-6 '>

                    <Link to={"/"} className={`font-semibold md:font-medium mx-2 md:mx-3 lg:mx-7 hover:navHover ${location?.pathname == "/" ? "navHover" : ""}`}>
                        Home
                    </Link>
                    <Link to={"/services"} className={`font-semibold md:font-medium mx-2 md:mx-3 lg:mx-7 hover:navHover ${location?.pathname == "/services" ? "navHover" : ""}`} >
                        Services
                    </Link>
                    {/* <Link to={"/contact"} className={`font-semibold md:font-medium mx-2 md:mx-3 lg:mx-7 hover:navHover ${location?.pathname == "/contact" ? "navHover" : ""}`}>
                            Contact Us
                        </Link> */}
                </div>
                <Link to={"/contact"} className=' mt-8 lg:px-6 px-4 py-1 lg:py-2 border border-[#01C47E] rounded-lg text-[#01C47E] hover:text-white hover:bg-[#01C47E] font-medium text-xl'>
                    Contact us
                </Link>
            </div>}
            <nav className='w-full sticky -top-[2px] h-[100px] bg-white bannerPadding-px  flex items-center justify-between z-50 shadow-md '>
                {/* <Link to=""> */}
                <Link to={"/"}>

                    <img src={logo} alt="" srcset="" className='h-12  w-28 md:h-14 md:w-32 lg:h-[4.375rem] lg:w-[9.875rem]' />
                </Link>

                {/* <img src={logo} alt="" srcset="" className='md:h-14 md:w-32 lg:h-[4.375rem] lg:w-[9.875rem]' /> */}
                {/* </Link> */}

                <button onClick={(() => {
                    setIsNavbarOpen(!isNavbarOpen)
                })} className="smallLogo  h-10 w-10 flex lg:hidden flex-shrink-0 items-center justify-center rounded-md text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" strokeWidth={1.5} stroke="white" className="w-6 h-6 text-white">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                    </svg>

                </button>

                <div className='hidden lg:block'>
                    <Link to={"/"} className={`font-semibold md:font-medium mx-2 md:mx-3 lg:mx-7 hover:navHover ${location?.pathname == "/" ? "navHover" : ""}`}>
                        Home
                    </Link>
                    <Link to={"/services"} className={`font-semibold md:font-medium mx-2 md:mx-3lg:mx-7 hover:navHover ${location?.pathname == "/services" ? "navHover" : ""}`} >
                        Services
                    </Link>
                    {/* <Link to={"/contact"} className={`font-semibold md:font-medium mx-2 md:mx-3 lg:mx-7 hover:navHover ${location?.pathname == "/contact" ? "navHover" : ""}`}>
                        Contact Us
                    </Link> */}
                </div>
                {/* {
                    location?.pathname == "/" ? */}
                <Link to={"/contact"} className='hidden lg:block lg:px-6 px-4 py-1 lg:py-2 border border-[#01C47E] rounded-lg text-[#01C47E] hover:text-white hover:bg-[#01C47E] font-medium text-xl'>
                    Contact us
                </Link>
                {/* : <div></div>
                } */}
            </nav>
        </>
    )
}

export default Navbar