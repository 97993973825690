import React from 'react'
import Button from './Button'

function PopUP({ data }) {
    let { setIsPopUpOpen, isPopUpOpenText } = data;
    return (
        <div className='fixed h-screen w-screen top-0 left-0  bg-opacity-90 z-30 flex items-center justify-center'>
            <div className='px-8 py-6 rounded-lg bg-white shadow-md'>
                <div className='flex items-center'>
                    <span className={` ${isPopUpOpenText == "Email Sent Successfully!!" ? "" : "text-red-500"}`}>
                        {isPopUpOpenText == "Email Sent Successfully!!" ?

                            <svg width="24" height="24" className='' viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.2507 8.75005C13.7507 11.25 11.8657 13.604 9.22071 14.13C7.9307 14.3869 6.59252 14.2303 5.39672 13.6824C4.20091 13.1346 3.20843 12.2234 2.56061 11.0786C1.91278 9.93389 1.64263 8.61393 1.78862 7.30672C1.93461 5.99951 2.4893 4.77167 3.37371 3.79805C5.18771 1.80005 8.25071 1.25005 10.7507 2.25005" stroke="url(#paint0_linear_52_30)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M5.75 7.75L8.25 10.25L14.25 3.75" stroke="url(#paint1_linear_52_30)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <defs>
                                    <linearGradient id="paint0_linear_52_30" x1="8.00036" y1="1.7627" x2="8.00036" y2="14.2504" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#0097CE" />
                                        <stop offset="0.43" stop-color="#01ABAB" />
                                        <stop offset="1" stop-color="#01C47E" />
                                    </linearGradient>
                                    <linearGradient id="paint1_linear_52_30" x1="10" y1="3.75" x2="10" y2="10.25" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#0097CE" />
                                        <stop offset="0.43" stop-color="#01ABAB" />
                                        <stop offset="1" stop-color="#01C47E" />
                                    </linearGradient>
                                </defs>
                            </svg> :
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="red" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
                            </svg>
                        }



                    </span>
                    <p className='ml-1 text-lg'>
                        {isPopUpOpenText}
                    </p>
                </div>
                <div className='flex justify-end mt-2'>
                    <Button label={"OK"} onClickFunc={()=>setIsPopUpOpen(false)} ></Button>
                </div>
            </div>
        </div>
    )
}

export default PopUP