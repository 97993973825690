import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from '../pages/Home'
import Sarvice from '../pages/Sarvice'
import Contact from '../pages/Contact'

function AppRouter() {
  return (
    <>
       <Routes>
                <Route exact={true} path="/" element={<Home />} />
                <Route exact={true} path="/services" element={<Sarvice />} />
                <Route exact={true} path="/contact" element={<Contact />} />
       </Routes>
    </>
  )
}

export default AppRouter