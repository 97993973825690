import marchent from "../images/marchent.png"
import rev from "../images/reverce.png"
import cre from "../images/credit.png"
import rev1 from "../images/rev1.png"
import cre1 from "../images/cre1.png"
import mar1 from "../images/mar1.png"

let banner2Data = [
    {
        img: marchent,
        label: "Merchant Cash Advance",
        para: "Secure quick and hassle-free capital up to 5 million dollars."
    },
    {
        img: rev,
        label: "Reverse Consolidation",
        para: "Lower your daily payment, reduce costs, and regain control over your business finances. "
    },
    {
        img: cre,
        label: "Line Of Credit",
        para: "Get access to our flexible line of credit up to 5 million dollars. No collateral, no credit check."
    },
]
let banner3Data = [
    {
        label: "Quick and Easy Application",
        para: "Lightning fast decision process, we will let you know the same day."
    },
    {
        label: "Same-Day Decision",
        para: "Simple application process designed to save you time and energy."
    },
    {
        label: "Receive Your Funds",
        para: "Funds deposited in your bank account same day"
    },
    {
        label: "Grow your business",
        para: "Use your new capital to grow your business to new heights"
    },
]
let banner4Data = [
    {
        icon: <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M28.865 26.5C26.9612 23.2087 24.0275 20.8487 20.6037 19.73C22.2973 18.7218 23.613 17.1856 24.349 15.3572C25.0849 13.5289 25.2004 11.5095 24.6775 9.60918C24.1547 7.70887 23.0226 6.03272 21.4549 4.83814C19.8873 3.64355 17.9709 2.99658 16 2.99658C14.0291 2.99658 12.1126 3.64355 10.545 4.83814C8.97737 6.03272 7.84522 7.70887 7.3224 9.60918C6.79958 11.5095 6.91501 13.5289 7.65096 15.3572C8.38691 17.1856 9.70268 18.7218 11.3962 19.73C7.97247 20.8475 5.03872 23.2075 3.13497 26.5C3.06516 26.6138 3.01885 26.7405 2.99878 26.8725C2.97872 27.0045 2.98529 27.1392 3.01813 27.2687C3.05096 27.3981 3.10939 27.5197 3.18996 27.6262C3.27053 27.7326 3.37162 27.8219 3.48725 27.8887C3.60289 27.9555 3.73073 27.9985 3.86323 28.015C3.99574 28.0316 4.13022 28.0215 4.25875 27.9853C4.38728 27.949 4.50725 27.8874 4.61158 27.8041C4.71592 27.7207 4.8025 27.6173 4.86622 27.5C7.22122 23.43 11.3837 21 16 21C20.6162 21 24.7787 23.43 27.1337 27.5C27.1974 27.6173 27.284 27.7207 27.3884 27.8041C27.4927 27.8874 27.6127 27.949 27.7412 27.9853C27.8697 28.0215 28.0042 28.0316 28.1367 28.015C28.2692 27.9985 28.3971 27.9555 28.5127 27.8887C28.6283 27.8219 28.7294 27.7326 28.81 27.6262C28.8906 27.5197 28.949 27.3981 28.9818 27.2687C29.0147 27.1392 29.0212 27.0045 29.0012 26.8725C28.9811 26.7405 28.9348 26.6138 28.865 26.5ZM8.99997 12C8.99997 10.6155 9.41051 9.26214 10.1797 8.11099C10.9489 6.95985 12.0421 6.06264 13.3212 5.53283C14.6003 5.00301 16.0077 4.86439 17.3656 5.13449C18.7235 5.40458 19.9708 6.07127 20.9497 7.05023C21.9287 8.0292 22.5954 9.27648 22.8655 10.6344C23.1356 11.9922 22.9969 13.3997 22.4671 14.6788C21.9373 15.9578 21.0401 17.0511 19.889 17.8203C18.7378 18.5894 17.3844 19 16 19C14.1441 18.998 12.3647 18.2599 11.0524 16.9475C9.74009 15.6352 9.00196 13.8559 8.99997 12Z" fill="white" />
        </svg>,
        label: "Team Of Experts",
        para: "Rely on our seasoned team of financial experts dedicated to navigating the intricacies of your business needs, providing strategic insights and personalized guidance for your success."
    },
    {
        icon: <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M28 6H4C3.46957 6 2.96086 6.21071 2.58579 6.58579C2.21071 6.96086 2 7.46957 2 8V24C2 24.5304 2.21071 25.0391 2.58579 25.4142C2.96086 25.7893 3.46957 26 4 26H28C28.5304 26 29.0391 25.7893 29.4142 25.4142C29.7893 25.0391 30 24.5304 30 24V8C30 7.46957 29.7893 6.96086 29.4142 6.58579C29.0391 6.21071 28.5304 6 28 6ZM28 8V11H4V8H28ZM28 24H4V13H28V24ZM26 21C26 21.2652 25.8946 21.5196 25.7071 21.7071C25.5196 21.8946 25.2652 22 25 22H21C20.7348 22 20.4804 21.8946 20.2929 21.7071C20.1054 21.5196 20 21.2652 20 21C20 20.7348 20.1054 20.4804 20.2929 20.2929C20.4804 20.1054 20.7348 20 21 20H25C25.2652 20 25.5196 20.1054 25.7071 20.2929C25.8946 20.4804 26 20.7348 26 21ZM18 21C18 21.2652 17.8946 21.5196 17.7071 21.7071C17.5196 21.8946 17.2652 22 17 22H15C14.7348 22 14.4804 21.8946 14.2929 21.7071C14.1054 21.5196 14 21.2652 14 21C14 20.7348 14.1054 20.4804 14.2929 20.2929C14.4804 20.1054 14.7348 20 15 20H17C17.2652 20 17.5196 20.1054 17.7071 20.2929C17.8946 20.4804 18 20.7348 18 21Z" fill="white" />
        </svg>
        ,
        label: "No Impact On Your Credit",
        para: "Secure the funding your business deserves without worrying about your credit score – our process ensures no impact on your credit, allowing you to explore financial opportunities with confidence."
    },
    {
        icon: <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 13H6V21H4C3.73478 21 3.48043 21.1053 3.29289 21.2929C3.10536 21.4804 3 21.7348 3 22C3 22.2652 3.10536 22.5196 3.29289 22.7071C3.48043 22.8946 3.73478 23 4 23H28C28.2652 23 28.5196 22.8946 28.7071 22.7071C28.8946 22.5196 29 22.2652 29 22C29 21.7348 28.8946 21.4804 28.7071 21.2929C28.5196 21.1053 28.2652 21 28 21H26V13H29C29.2176 12.9998 29.4292 12.9286 29.6026 12.7973C29.7761 12.6659 29.902 12.4816 29.9612 12.2722C30.0205 12.0629 30.0098 11.8399 29.9308 11.6371C29.8519 11.4344 29.709 11.2629 29.5238 11.1487L16.5238 3.14874C16.3662 3.05189 16.1849 3.00061 16 3.00061C15.8151 3.00061 15.6338 3.05189 15.4762 3.14874L2.47625 11.1487C2.29103 11.2629 2.14811 11.4344 2.06916 11.6371C1.99021 11.8399 1.97955 12.0629 2.03878 12.2722C2.09801 12.4816 2.22391 12.6659 2.39738 12.7973C2.57085 12.9286 2.78242 12.9998 3 13ZM8 13H12V21H8V13ZM18 13V21H14V13H18ZM24 21H20V13H24V21ZM16 5.17374L25.4675 11H6.5325L16 5.17374ZM31 26C31 26.2652 30.8946 26.5196 30.7071 26.7071C30.5196 26.8946 30.2652 27 30 27H2C1.73478 27 1.48043 26.8946 1.29289 26.7071C1.10536 26.5196 1 26.2652 1 26C1 25.7348 1.10536 25.4804 1.29289 25.2929C1.48043 25.1053 1.73478 25 2 25H30C30.2652 25 30.5196 25.1053 30.7071 25.2929C30.8946 25.4804 31 25.7348 31 26Z" fill="white" />
        </svg>
        ,
        label: "No Collateral",
        para: "Unlock capital without putting your assets at risk; our no-collateral approach provides a secure pathway for obtaining funds, allowing you to invest in your business without compromising your assets."
    },
    {
        icon: <svg width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_96_159)">
                <path d="M26 4H23V3C23 2.73478 22.8946 2.48043 22.7071 2.29289C22.5196 2.10536 22.2652 2 22 2C21.7348 2 21.4804 2.10536 21.2929 2.29289C21.1054 2.48043 21 2.73478 21 3V4H11V3C11 2.73478 10.8946 2.48043 10.7071 2.29289C10.5196 2.10536 10.2652 2 10 2C9.73478 2 9.48043 2.10536 9.29289 2.29289C9.10536 2.48043 9 2.73478 9 3V4H6C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V26C4 26.5304 4.21071 27.0391 4.58579 27.4142C4.96086 27.7893 5.46957 28 6 28H26C26.5304 28 27.0391 27.7893 27.4142 27.4142C27.7893 27.0391 28 26.5304 28 26V6C28 5.46957 27.7893 4.96086 27.4142 4.58579C27.0391 4.21071 26.5304 4 26 4ZM9 6V7C9 7.26522 9.10536 7.51957 9.29289 7.70711C9.48043 7.89464 9.73478 8 10 8C10.2652 8 10.5196 7.89464 10.7071 7.70711C10.8946 7.51957 11 7.26522 11 7V6H21V7C21 7.26522 21.1054 7.51957 21.2929 7.70711C21.4804 7.89464 21.7348 8 22 8C22.2652 8 22.5196 7.89464 22.7071 7.70711C22.8946 7.51957 23 7.26522 23 7V6H26V10H6V6H9ZM26 26H6V12H26V26Z" fill="white" />
            </g>
            <defs>
                <filter id="filter0_d_96_159" x="-4" y="0" width="40" height="40" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_96_159" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_96_159" result="shape" />
                </filter>
            </defs>
        </svg>
        ,
        label: "Same Day Approval",
        para: "Unlock capital without putting your assets at risk; our no-collateral approach provides a secure pathway for obtaining funds, allowing you to invest in your business without compromising your assets."
    },
]
let banner8Data = [
    {
        img: mar1,
        title: "Merchant Cash Advance",
        label: "Unlock Your Business Potential",
        para: "Secure quick and hassle-free capital with our Merchant Cash Advance, tailored to support your business growth.",
        bulletPoints: [
            "Funding up to $5 million",
            "No collateral required",
            "No credit check"
        ]
    },
    {
        img: rev1,
        title: "Reverse Consolidation",
        label: "Streamline Your Finances",
        para: "Take control of your business finances with our Reverse Consolidation service, designed to lower daily payments, reduce costs, and empower your financial management.",
        bulletPoints: [
            "Lower your daily cash advance payments",
            "Save money by reducing expenses",
            "No collateral required",
            "No credit check"
        ]
    },
    {
        img: cre1,
        title: "Line of Credit",
        label: "Flexible Funding at Your Fingertips",
        para: "Access a flexible line of credit, providing your business with financial agility up to $5 million.",
        bulletPoints: [
            "Funding up to $5 million",
            "Flexible line of credit options",
            "No collateral required",
            "No credit check"
        ]
    },
];
let banner9Data = [
    {
        svg: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
        </svg>,
        label:"Address",
        para:"99 Wall Street #2497, New York NY 10005"
    },
    {
        svg: <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
      </svg>,
        label:"Email",
        para:"submissions@genesisequitygroup.com"
    },
    {
        svg: <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>,
        label:"Hours of Operation",
        para:"Friday: 9am - 6pm"
    },
    {
        svg: <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
      </svg>,
        label:"Phone Number",
        para:"+1 (718) 705-4214"
    },
]

export { banner2Data, banner3Data, banner4Data, banner8Data,banner9Data }