import React from 'react'
import { banner2Data, banner3Data, banner4Data } from "../asset/staticData";
import Button from "../components/Button";
import Card1 from "../components/Card1";
import Card2 from "../components/Card2";
import Card4 from "../components/Card4";
import business from "../images/buisness.png"
import { useNavigate } from 'react-router-dom';

function Home() {
  const navigate = useNavigate();
  return (
    <>
      <div className="banner1 flex w-full  justify-center lg:justify-start bannerPadding-px bannerPadding-py py-0">
        <div className="flex flex-col lg:items-start items-center text-white  w-full xl:w-2/3 3xl:w-1/2">
          <h1 className="font-bold text-5xl md:text-[4rem] xl:text-[5rem] mb-12 text-center lg:text-left text-white ">
            Unlock Flexible <br /> Funding Solutions
          </h1>
          <p className="text-lg mb-14 lg:text-left text-center text-white">
            Take advantage of our flexible financing options tailored to your specific
            <br className="" />
            financial needs. No collateral, no credit check.
          </p>
          <Button label={"Get Funded Now"} onClickFunc={() => navigate("/contact")} />
        </div>
      </div>
      <div className="banner2 bannerPadding-px  bannerPadding-py mb-25 relative">
        {/* blur span */}
        {/* <div className=' absolute -left-1/2 -top-1/4 h-[48rem] w-[46rem] rounded-full bg-[#0097CE] bg-opacity-25 blur-xl'></div> */}
        <div className="top w-full flex items-center justify-center flex-col pb-9">

          <h3 className=" text-4xl text-center md:text-5xl font-bold mb-6">
            Solution For Every Business Need
          </h3>
          <p className="text-center text-lg">
            Tailored solutions to meet the unique demands of every business, <br /> ensuring comprehensive support and success.
          </p>
        </div>

        <div className="flex flex-col md:flex-row items-center justify-between gap-4">
          {/* <div className="flex flex-col md:flex-row items-center md:items-start justify-center gap-4"> */}
          {banner2Data?.map((item, i) => {
            return <Card1 data={item} key={i} />
          })}
        </div>
      </div>
      <div className="banner3 bannerPadding-px bannerPadding-py mb-16 mb:20 xl:mb-25">
        <div className="top flex xl:flex-row flex-col xl:justify-between items-center mb-8 2xl:mb-12">
          <h3 className="text-4xl md:text-5xl font-bold xl:w-1/2 text-center xl:text-left mb-4 xl:mb-0  ">
            Steps For Getting Your Business Into Next Level
          </h3>
          <Button label={"Get Funded Now"} onClickFunc={() => navigate("/contact")}></Button>
        </div>


        <div className="flex  xl:flex-row flex-col xl:gap-12">


          <div className="left flex flex-col xl:w-1/2">


            <p className=" mb-8 2xl:mb-12 text-center md:text-left ">
              Our dedicated team of professionals is here for you every step of the way, offering expertise and support to guide your business towards success. Your vision for the future is our priority, and we are committed to providing the resources and assistance you need to take your business to the next level.
            </p>

            <img src={business} alt="" className="rounded-md" />

          </div>
          <div className="right  xl:w-1/2">
            <div className="w-full h-full flex flex-col justify-center mt-6 xl:mt-0 md:grid grid-rows-2 grid-cols-2 gap-4 ">
              {banner3Data?.map((item, i) => {
                return <Card2 data={item} key={i} />
              })}

            </div>
          </div>
        </div>
      </div>
      {/* banner4 */}
      <div className="banner4 bannerPadding-px pt-12 pb-[20rem] ">
        <h3 className="text-4xl md:text-5xl text-white text-center font-bold mb-6">
          We Are Trusted Partner
          <br />
          Help You Grow
        </h3>
        <div className="w-full flex flex-col md:grid grid-cols-2 grid-rows-2 xl:flex-row xl:flex gap-4 justify-between max-h-fit">
          {banner4Data?.map((item, i) => {
            return <Card4 data={item} key={i} />
          })}
        </div>



      </div>
      <div className="musk relative h-[18rem] md:h-0">

        <div className=" absolute bannerPadding-px bottom-[-10%] sm:bottom-[-10%] md:-bottom-1/3   h-fit xl:h-[17.625rem] w-full z-10">
          <div className="w-full h-fit md:h-full banner5 flex justify-between items-center md:items-start xl:items-center flex-col xl:flex-row rounded-xl overflow-hidden px-11 py-12 xl:py-0" >
            <div className="left xl:w-1/2">
              <h3 className="text-3xl sm:text-4xl text-white text-center md:text-left  font-bold mb-6">
                Secure Your Business Growth Now
              </h3>
              <p className="text-lg text-center md:text-left mb-14 lg:text-left text-white">
                Ready to propel your business forward? Take the first step towards financial success by getting in touch with our expert team now.

              </p>
            </div>
            <Button label={"Get Funded Now"} onClickFunc={() => navigate("/contact")} type={"green"} />
          </div>
        </div>
      </div>
    </>
  )
}

export default Home